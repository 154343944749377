<template>
  <div class="main">
    <NavBar />
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
    </div>

    <!-- Content -->
    <div v-else class="content-width">
      <!-- Course -->
      <div class="col-span-1">
        <Select
          v-show="!table_loading"
          v-bind:data="data"
          v-bind:typeSelected="this.filtered.type"
          v-bind:yearSelected="this.filtered.year"
          v-bind:semesterSelected="this.filtered.sems"
          v-bind:prevCourse="this.filtered.prev"
          v-for="data in yearCourses"
          :key="data.id"
          @changeSelected="changeSelected"
        />
      </div>

      <!-- Header -->
      <div class="manage-header">
        <div class="row"  v-show="!table_loading">
          <p class="table-header-with-button">ข้อมูลโครงงาน</p>
          <button v-if="role === 'TCH' && !filtered.is_advisor" @click="filtered.is_advisor = !filtered.is_advisor; changeSelectedCourse()" class="file-button mx-auto" style="background-color:green">ดูเฉพาะโครงงานที่รับผิดชอบ</button>
          <button v-if="role === 'TCH' && filtered.is_advisor" @click="filtered.is_advisor = !filtered.is_advisor; changeSelectedCourse()" class="file-button mx-auto" style="background-color:green">ดูทุกโครงงาน</button>
          
          <!-- Search project -->
          <p class="ml-auto my-auto">ค้นหา</p>
          <input v-model="search_keyword" @change="projectFilter()" type="text" placeholder="Keyword" class="select-bar"/>
        </div>

        <div class="row" v-show="!table_loading">
          <!-- Download: project file -->
          <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 my-auto" fill="none" viewBox="0 0 24 24" stroke="black"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/></svg>
          <button v-if="role !== 'STD'" @click="downloadFile('csv')" class="file-button ml-4">.csv</button>
          <button v-if="role !== 'STD'" @click="downloadFile('xlsx')" class="file-button ml-4">.xlsx</button>
          
          <!-- Program filter -->
          <p class="ml-auto my-auto">หลักสูตร</p>
          <select v-model="filtered.program" @change="changeSelectedCourse()" class="select-bar">
            <option value="ALL">ทั้งหมด</option>
            <option v-for="program in programs" :key="program.id" :value="program.code">{{ program.name }}</option>
          </select>
        </div>

        <!-- Table loading -->
        <div v-if="table_loading" class="mt-8">
          <div class="loader m-auto"></div>
        </div>
        <div v-else-if="filtered.project.length > 0">
          <!-- Project list (Table) -->
          <table>
            <!-- Table header -->
            <thead>
              <tr>
                <th class="w-1/12">ลำดับ</th>
                <th class="w-4/12">หัวข้อโครงงาน</th>
                <th class="w-2/12">รหัสนักศึกษา</th>
                <th class="w-2/12">นักศึกษาผู้รับผิดชอบ</th>
                <th class="w-3/12">อาจารย์ที่ปรึกษา</th>
                <th></th>
              </tr>
            </thead>
            <!-- Table data  -->
            <tbody>
              <tr><td colspan="7"><hr></td></tr>
              <tr v-for="(project, index) in filtered.project" :key="project.id">
                <td>{{ index + 1 }}</td>
                <td class="text-left">{{ project.topic_th }}<br>({{ project.topic_en }})</td>
                <td class="whitespace-pre ...">
                  <div v-for="member in project.member_details" :key="member.id">{{ member.code }}</div>
                </td>
                <td class="whitespace-pre ...">
                  <div v-for="member in project.member_details" :key="member.id">{{ member.name }}</div>
                </td>
                <td class="whitespace-pre ...">
                  <div v-for="advisor in project.advisor_details" :key="advisor.id">{{ advisor.name }}</div>
                </td>
                <td>
                  <router-link :to="'/project/' + project.id + '/'">
                    <button class="view-button mx-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="white">
                        <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Not Found -->
        <div v-else class="text-not-found">ไม่พบข้อมูลที่สอดคล้องกับตัวกรองข้อมูลที่เลือก</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import Select from "@/components/Select"
import { changeProgramName, changeProgramByType, yearCourseSelect, checkCoadvisor } from "../mixins/other"
import * as XLSX from "xlsx/xlsx.mjs"

export default {
  name: "ProjectManage",
  components: {
    NavBar,
    Select,
  },
  data() {
    return {
      loading: true,
      table_loading: true,
      courses: null,
      course: null,
      projects: null,
      programs: [],
      years: [],
      yearCourses: [],
      filtered: {
        project: null,
        is_advisor: false
      },
      search_keyword: "",
      role: null,
    }
  },
  async created() {
    document.title = "ข้อมูลโครงงาน | Carbon"
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role === "STD") {
      this.$router.push("/")
    }
    await this.setSelected()
    let { years, yearCourses } = yearCourseSelect(this.courses)
    this.years = years
    this.yearCourses = yearCourses
    await this.changeSelectedCourse()
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.filtered.type))
    this.loading = false
    this.table_loading = false
  },
  methods: {
    async setSelected() {
      this.courses = await this.$store.dispatch("getCourse")
      this.courses.sort(function (c1, c2) { return c1.code - c2.code })
      this.courses.sort(function (c1, c2) { return c2.year - c1.year })
      this.filtered.course = this.courses[0]
      this.filtered.year = this.filtered.course.year
      this.filtered.type = this.filtered.course.type
      this.filtered.sems = (this.courses[0].type == 'PJ') ? null : this.filtered.course.semester
      this.filtered.prev = null
      this.filtered.program = "ALL"
    },
    changeSelected(year, type, sems, prev) {
      this.filtered.year = year
      this.filtered.type = type
      this.filtered.sems = sems
      this.filtered.prev = prev
      this.filtered.program = "ALL"
      this.programs = changeProgramName(changeProgramByType(this.filtered.type))
      this.changeSelectedCourse()
    },
    async projectFilter() {
      this.table_loading = true
      this.filtered.project = []
      let data =  Object.values(this.filtered.course)
      this.filtered.project = await this.$store.dispatch("getFilteredProject", { courses: data, search_keyword: this.search_keyword.toLowerCase(), user_id: this.$store.getters.payload.user_id, role: this.role, is_advisor: this.filtered.is_advisor })
      this.table_loading = false
    },
    async changeSelectedCourse() {
      this.filtered.course = []
      let courseList = []
      this.courses.filter((course) => {
        if (course.year == this.filtered.year && course.type == this.filtered.type && course.type_is == this.filtered.sems && this.filtered.type == 'IS') {
          // กรณี IS จะไม่สนใจ sems จะสนใจที่ is_type ซึ่ง this.filtered.sems จะถูกส่งมาเป็น 1 สำหรับ IS 1 และ 2 สำหรับ IS 2
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && this.filtered.type == 'CP') {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course == null && this.filtered.type == 'PJ' && this.filtered.prev == null) {
          courseList.push([course.id, course.program_code])
        } else if (course.year == this.filtered.year && course.type == this.filtered.type && course.prev_course != null && this.filtered.type == 'PJ' && this.filtered.prev != null) {
          courseList.push([course.prev_course, course.program_code])
        }
      })
      courseList.filter((course) => {
        if (this.filtered.program == 'ALL' || this.filtered.program == course[1]) {
          this.filtered.course.push(course[0])
        }
      })
      await this.projectFilter()
    },
    downloadFile(type) {
      if (this.filtered.project.length == 0 || this.filtered.project.length == undefined) {
        alert("ไม่พบข้อมูลโครงงานที่สอดคล้องกับรายวิชาและหลักสูตรที่เลือกภายในระบบ")
      } else {
        let data = this.filtered.project
        data = data.map(function(pj, index) { 
          return { 
            ลำดับที่: index + 1,
            ปีการศึกษา: pj.course_details.year,  
            หลักสูตร: pj.course_details.program_name,
            ชนิดโครงงาน: pj.course_details.type_name,
            uuid: pj.uuid,
            ชื่อโครงงานภาษาไทย: pj.topic_th,
            ชื่อโครงงานภาษาอังกฤษ: pj.topic_en,
            ประเภทโครงงาน: pj.course_details.type_name,
            "รหัสนักศึกษา": pj.member_details[0].code,
            "ชื่อ-นามสกุล": pj.member_details[0].name,
            "รหัสนักศึกษา (2)": pj.member_details.length > 1 ? pj.member_details[1].code : "-",
            "ชื่อ-นามสกุล (2)": pj.member_details.length > 1 ? pj.member_details[1].name : "-",
            อาจารย์ที่ปรึกษา: pj.advisor_details[0].role == 'A' ? pj.advisor_details[0].name : "-",
            อาจารย์ที่ปรึกษาร่วม: checkCoadvisor(pj.advisor_details),
            หมวดหมู่โครงงาน: pj.category_detail.name,
            คำสำคัญ: pj.keyword,
            วัตถุประสงค์: pj.objective,
            วิธีการดำเนินงาน: pj.process,
            ขอบเขตของงาน: pj.scope,
            ประโยชน์ที่คาดว่าจะได้รับ: pj.benefit
          } 
        })
        let fileName = "สรุปข้อมูลนักศึกษาเสนอหัวข้อวิชา" + data[0].ประเภทโครงงาน + " หลักสูตร" + this.filtered.program + "." + type
        const dataWS = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(wb,fileName)
      }
    },
  },
}
</script>